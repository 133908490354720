import { click } from './tracking';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 * @param {PointerEvent|Event} event Click event received
 */
export default function bindClickEvent(element, event) {
  // get tracking key and other infos
  const trackingKey = element.getAttribute('data-tracking');
  const trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  let href = element.getAttribute(element.nodeName === 'A' ? 'href' : 'data-href');

  // send click tracking
  click(trackingKey, trackingData).then((response) => {
    // redirect after the click
    if (element.nodeName === 'A') {
      if (response.length) {
        href = response;
      }

      const isEventObject = typeof event === 'object';

      if (isEventObject && 'shiftKey' in event) {
        window.open(href, '_blank');
      } else if (isEventObject && 'altKey' in event) {
        const a = document.createElement('a');
        a.setAttribute('download', element.getAttribute('data-name'));
        a.click();
      } else if (isEventObject && 'ctrlKey' in event) {
        window.open(href, '_blank');
      } else {
        window.location.assign(href);
      }
    }
  });
}
