import { closest } from "../helpers/dom";

export default class CategoriesChildren {
    /**
     * Container element
     * @type {HTMLElement}
     */
    container = null;

    /**
     * Toggle class used
     * @type {string}
     */
    toggleClass = 'category__container--toggle';

    /**
     * Application state
     * @type {State}
     */
    $state = undefined;

    /**
     * @param {State} $state
     */
    constructor($state) {
        this.$state = $state;

        this.container = document.querySelector('.inbenta-km__categories-children');

        this.#init();
    }

    #init() {
        if (this.container) {
            const categories = this.container.querySelectorAll('.inbenta-km__categories-children [data-collapse]');
            categories.forEach((collapsable) => collapsable.addEventListener('click', (ev) => {
                // get parent container
                const parent = collapsable.parentElement.parentElement;

                // check if already toggled
                if (collapsable.hasAttribute('data-toggled')) {
                    // remove toggle
                    this.setToggled(collapsable, false);
                } else {
                    // set toggle
                    this.setToggled(collapsable);
                }

                // check already toggled element to close them
                const toggled = parent.querySelectorAll('[data-toggled]');
                toggled.forEach((element) => {
                    if (element !== collapsable) {
                        this.setToggled(element, false);
                    }
                });
            }));
        }
    }

    /**
     * Toggle the given category element
     *
     * @param {HTMLElement} element
     * @param {boolean} isToggled
     */
    setToggled(element, isToggled = true) {
        const parent = element.parentElement.parentElement;
        const target = parent.querySelector(element.getAttribute('data-target'));
        const heightMultiplier = 2;

        if (isToggled) {
            target.classList.add(this.toggleClass);
            element.setAttribute('data-toggled', '');

            // update self max-height
            const height = target.scrollHeight * heightMultiplier;
            target.setAttribute('style', `max-height: ${height}px;`);

            // try updating the closest parent max-height
            setTimeout(() => {
                const ownParent = closest(parent, `.${this.toggleClass}`);
                if (ownParent) {
                    const ownParentHeight = ownParent.scrollHeight * heightMultiplier;
                    ownParent.setAttribute('style', `max-height: ${ownParentHeight}px;`);
                }
            }, 350);
        } else {
            target.classList.remove(this.toggleClass);
            element.removeAttribute('data-toggled');

            // update self max-height
            target.setAttribute('style', `max-height: 0;`);

            // try updating the closest parent max-height
            setTimeout(() => {
                const ownParent = closest(parent, `.${this.toggleClass}`);
                if (ownParent) {
                    const ownParentHeight = ownParent.scrollHeight * heightMultiplier;
                    ownParent.setAttribute('style', `max-height: ${ownParentHeight}px;`);
                }
            }, 350);
        }
    }

}
