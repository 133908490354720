/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import { click } from '../helpers/tracking';
import bindClickEvent from '../helpers/clicks';

export default class Clicks {
  /**
   * Application state
   * @type {State}
   */
  $state = undefined;

  /**
   * @param {State} $state
   * @param {Record<string, any>} options
   */
  constructor($state, options = {}) {
    this.$state = $state;

    this.options = Object.assign(
      {},
      {
        selector: 'a[data-tracking], div[data-tracking]',
      },
      options,
    );

    this.#init();
  }

  /**
   * Initialize module
   */
  #init() {
    const trackingLinks = document.querySelectorAll(this.options.selector);
    trackingLinks.forEach((link) => {
      link.addEventListener('auxclick', (event) => {
        event.preventDefault();
        event.target.click();
      });
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const { currentTarget } = event;

        const container = Clicks.#findParent(currentTarget);
        if (container) {
          const faqs = Clicks.#findParent(container, 'inbenta-km__faqs');

          if (faqs && faqs.classList.contains('inbenta-km__faqs--toggle')) {
            // should handle the content toggle
            // find the currently opened content
            const opened = faqs.querySelector('.inbenta-km__faq--active');

            if (opened && container && opened === container) {
              // simply close the content
              container.classList.toggle('inbenta-km__faq--active');
            } else {
              // close the opened content & open the clicked one
              if (opened) {
                opened.classList.toggle('inbenta-km__faq--active');
              }
              container.classList.toggle('inbenta-km__faq--active');
            }
          }

          // send click
          bindClickEvent(currentTarget, event);
        } else {
          // should just send the click and navigate to the content link
          bindClickEvent(currentTarget, event);
        }
      });
    });
  }


  /**
   * Find and return the parent `inbenta-km__faq` from the given element
   *
   * @param {HTMLElement|Element} element Element to start the search
   * @param {string} classElement Class to check to find the parent element
   *
   * @return {HTMLElement|Element} Parent element
   */
  static #findParent(element, classElement = 'inbenta-km__faq') {
    const parent = element.parentElement;

    if (!parent) {
      return null;
    }

    if (parent.classList.contains(classElement)) {
      return parent;
    }

    return Clicks.#findParent(parent);
  }

}
