/**
 * Return the closest parent to the given element using the given selector.
 *
 * @param {HTMLElement|Element} element Element used to start the search
 * @param {string} selector Selector string
 * @return {HTMLElement|null}
 */
export function closest(element, selector) {
    let matchesFn;

    // find vendor prefix
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
        if (typeof document.body[fn] === 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    });

    let parent;

    // traverse parents
    let el = element;
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}
