// import modules that define the main functionalities of the app
import Autocompleter from 'modules/autocompleter';
import Clicks from 'modules/clicks';
import Ratings from 'modules/ratings';
import CategoriesChildren from 'modules/categories-children';
import state from 'helpers/state';

export default class App {
  /**
   * Autocompleter module instance
   *
   * @type {Autocompleter}
   */
  autocompleter;

  /**
   * Clicks module instance
   *
   * @type {Clicks}
   */
  clicks;

  /**
   * Ratings module instance
   *
   * @type {Ratings}
   */
  ratings;

  /**
   * Categories children module instance
   * @type {CategoriesChildren}
   */
  categoriesChildren;

  /**
   * Application state
   *
   * @type {State|null}
   */
  $state = null;

  constructor(options = {}) {
    this.$state = state;
    this.$state.environment = inbenta.seo.env.INBENTA_ENV;

    Object.keys(options).forEach((option) => {
      if (this.$state.hasOwnProperty(option)) {
        this.$state[option] = options[option];
      }
    });
  }

  initialize() {
    this.autocompleter = new Autocompleter(this.$state);
    this.clicks = new Clicks(this.$state);
    this.ratings = new Ratings(this.$state);
    this.categoriesChildren = new CategoriesChildren(this.$state);

    // handle header resize
    this.handleHeaderResize();

    // handle search box search
    this.handleSearchBox();
  }

  handleHeaderResize() {
    const headerContainer = document.querySelector('.inbenta-helpsite__header');
    const header = headerContainer.querySelector('header');

    const resizeHeaderContainer = () => {
      const headerContainer = document.querySelector('.inbenta-helpsite__header');
      const header = headerContainer.querySelector('header');
      const height = header.offsetHeight;
      headerContainer.removeAttribute('style');
      headerContainer.setAttribute('style', `height: ${height}px;`);
    };

    // resize header container on document resize
    document.addEventListener('resize', () => resizeHeaderContainer());

    // resize header container on any mutation observed in the header element
    const observer = new MutationObserver(() => resizeHeaderContainer());
    observer.observe(header, { attributes: true, childList: true, characterData: true, subtree: true });

    // resize header container on initialization
    resizeHeaderContainer();

    // setting up an interval the first 10 seconds to resize the header in case of slow loading time
    const interval = setInterval(() => resizeHeaderContainer(), 1000);
    setTimeout(() => clearInterval(interval), 10000);
  }

  handleSearchBox() {
    /** @type {HTMLFormElement} */
    const searchForm = document.querySelector('.inbenta-helpsite__body .inbenta-km__search form');
    if (searchForm) {
      searchForm.addEventListener('submit', (event) => {
        if (this.$state.isAutocompleterLoading === true) {
          event.preventDefault();

          // wait for it to finish loading
          const waitForAutocompleterInterval = setInterval(() => {
            console.info('Wait for autocompleter...');
            if (this.$state.isAutocompleterLoading === false) {
              // clear interval & submit the search again
              console.info('Autocompleter finished...redirecting to response page...');
              clearInterval(waitForAutocompleterInterval);
              searchForm.submit();
            }
          }, 50);
        }
      });
    }
  }
}
