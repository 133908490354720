
class State {
    /**
     * Current environment (development, preproduction or production)
     * @type {string}
     */
    environment = undefined;

    isAutocompleterLoading = false;
    isSearchLoading = false;
}

const state = new State();

export default state;
